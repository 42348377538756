import React, { useState } from "react";
import "./Login.css";

import axios from "axios";


const Login = ({ onLogin }) => {

    const handleToggleMode = () => {
        const body = document.body;
        body.classList.toggle('dark-mode'); // Toggle dark mode class on the body

        const modeToggle = document.querySelector('.login-mode-toggle');
        const currentMode = body.classList.contains('dark-mode') ? '🌚' : '🌞'; // Toggle the icon
        modeToggle.textContent = currentMode; // Update the icon
    }


    const [token, setToken] = useState('');

    const handleTokenChange = (event) => {
        let inputToken = event.target.value.replace(/[^0-9A-Za-z]/g, '').toUpperCase();
        let formattedToken = '';

        // Format token with dashes
        for (let i = 0; i < inputToken.length; i++) {
            if (i > 0 && i % 4 === 0) {
                formattedToken += '-';
            }
            formattedToken += inputToken.charAt(i);
        }

        // Restrict the token length to 19 characters (16 characters + 3 dashes)
        setToken(formattedToken.substring(0, 19));
    };





    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            //TEMPORARY REMOVAL setLoading(true); // Start loading animation
            console.log("Enter key pressed. Calling function...");
            if (token.length === 19) {
                //console.log("Wasting request bandwidth");
                checkLoginCredentials();
            } else {
                //TEMPORARY REMOVAL setLoading(false); // Stop loading animation
            }
        }
    };

    const handleLoginButton = (event) => {
        if (token.length === 19) {
            //console.log("Wasting request bandwidth");
            checkLoginCredentials();
        } else {
            //TEMPORARY REMOVAL setLoading(false); // Stop loading animation
        }
    };







    //CLOUDFLARE REMOVAL const [readCellValue, setCellValue] = useState('');
    //TEMPORARY REMOVAL const [loading, setLoading] = useState(false); // State for loading animation
    
    
    //CLOUDFLARE REMOVAL const [rowNumberID, setRowNumberID] = useState('');


    const checkLoginCredentials = async (e) => {
        try {
            const response = await axios.post('http://localhost:3001/read-value', { argument: "login-token", token: token });
            
            await response.data;

            console.log(response.data);
            console.log(response.data.cellValue);
            console.log(response.data.rowNumberID);

            //CLOUDFLARE REMOVAL setRowNumberID(response.data.rowNumberID);


            if (response.data.cellValue === token.trim()) {
                console.log("login token exists");
                console.log(token, response.data.rowNumberID);
                onLogin(token, response.data.rowNumberID);
            } else {
                console.log("login token doesn't exist");
                //CLOUDFLARE REMOVAL readCellValue = "";
            }
        } catch (error) {
            //TEMPORARY REMOVAL setLoading(false); // Stop loading animation in case of error
            console.error('Error getting cell value:', error);
        }
        
    };


    return (
        <div className="Login">



            <div className="login-logo-container">
                <img src = {require("./Logo Placeholder.png")} alt="ALT TEXT" className="logo"/>
            </div>

            <div className="login-mode-toggle" onClick={handleToggleMode}>🌞</div>

            <div className="login-main-container">
                <div className="login-container">
                    <h2 className="login-h2">NEXUS-INSIGHT</h2>
                    <input type="text" id="tokenInput" className="login-token-input" placeholder="Please enter token (XXXX-XXXX-XXXX-XXXX)" value={token} onChange={handleTokenChange} onKeyDown={handleKeyDown} />
                    {/* {loading && <div className="login-loading">Loading...</div>} {/* Loading animation */}
                    <button className="login-button" onClick={handleLoginButton}>Login</button>
                </div>
            </div>
        </div>
    );
}

export default Login;