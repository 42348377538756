// App.js or your routing configuration file
import React, {useState} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './Home';
import Login from './Login';

function App() {


  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(null);
  const [rowNumberID, setRowNumberID] = useState(null);
  const [remainingTokens, setRemainingTokens] = useState(null);

  const handleLogin = (token, rowNumberID) => {
    // Perform login logic, set user data, set isLoggedIn to true
    setToken(token);
    setRowNumberID(rowNumberID);
    setRemainingTokens(remainingTokens);
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setToken(null);
    setRowNumberID(null);
    setRemainingTokens(null);
    setIsLoggedIn(false);
  };


  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={isLoggedIn ? <Navigate to="/home" /> : <Login onLogin={handleLogin} />}
        />
        <Route
          path="/home"
          element={isLoggedIn ? <Home token={token} rowNumberID={rowNumberID} remainingTokens={remainingTokens} onLogout={handleLogout} /> : <Navigate to="/" />}
        />
      </Routes>
    </Router>
  );
}

export default App;
