import React, { useState, useEffect, useRef, useCallback } from "react";
import "./Home.css";

import axios from "axios";


//export default StripePricingTable;


const Home = ({ token, rowNumberID, onLogout }) => {

  const handleToggleMode = () => {
    const body = document.body;
    body.classList.toggle('dark-mode'); // Toggle dark mode class on the body

    const modeToggle = document.querySelector('.home-mode-toggle');
    const currentMode = body.classList.contains('dark-mode') ? '🌚' : '🌞'; // Toggle the icon
    modeToggle.textContent = currentMode; // Update the icon
  }


  //const [remainingTokens, setRemainingTokens] = useState("");
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");



  const handleSubmit = (e) => {
    e.preventDefault();

    if (tokenCount === 0) {
      console.log("nothing entered in the textarea");
    } else {

      axios.post('http://localhost:3001/read-value', { argument: "remaining", rowNumberID: rowNumberID })
        .then(response1 => {
          const remainingTokensValue = response1.data.remainingTokens;
          //setRemainingTokens(remainingTokensValue);
          //console.log("this is the returned remainingTokens variable:", remainingTokens);
          console.log("this is the returned remainingTokensVALUE variable:", remainingTokensValue);
          console.log("this is the returned tokenCount variable:", tokenCount);

          //console.log("this the the return type of the remainingTokens variable:", typeof(parseInt(remainingTokens)));
          console.log("this the the return type of the remainingTokensVALUE variable:", typeof(parseInt(remainingTokensValue)));
          console.log("this the the return type of the tokenCount variable:", typeof(tokenCount));

          if (tokenCount <= remainingTokensValue) {
            console.log("had enough tokens");
            axios.post("http://localhost:3001/gpt-api-call", { message }, {
              headers: {
                "Content-Type": "application/json",
              }
            })
              .then((response2) => {
                setResponse(response2.data.message);
              })
              .catch((error) => {
                console.error('Error in gpt-api-call request:', error);
              });

            axios.post("http://localhost:3001/modify-cell", {newTokensUsed: parseInt(readCellValue) + tokenCount, rowNumberID: rowNumberID})
              .then(response3 => {
                console.log(response3.data);
                fetchData();
            })
            .catch(error => {
                console.error(error);
            });

          } else if (tokenCount > remainingTokensValue) {
            console.log("buy more tokens");
            openPopup();
          } else {
            console.log("gone wrong");
          }

        })
        .catch(error => {
          console.error('Error in read-value request:', error);
        });
    }
    
  };


  const handleClearInput = (e) => {
    e.preventDefault(); // Prevent the default form submission
    setMessage(''); // Clear the textarea


    setTokenCount(0);
  };



  const handleIncreaseLimitRedirect = () => {
    // Redirect the user to the desired link when the button is clicked
    window.location.href = 'https://buy.stripe.com/test_cN25m90Iqdp46dy8ww';
  };



  const serverOutputRef = useRef(null);

  const handleCopyToClipboard = () => {
    if (serverOutputRef.current) {
        serverOutputRef.current.select();
        serverOutputRef.current.setSelectionRange(0, 99999); // For mobile devices
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
    }
  };


  const [tokenCount, setTokenCount] = useState(0);


  const handleChange = (e) => {
    const inputText = e.target.value;
    
    setMessage(inputText);

    // Send the input text to the backend to get the token count
    axios.post('http://localhost:3001/token-count', { message: inputText })
      .then(response => {
          const { tokenCount } = response.data;
          setTokenCount(tokenCount);
      })
      .catch(error => {
          console.error('Error calculating token count:', error);
      });
  };

  
  
  const [readCellValue, setCellValue] = useState('');

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.post('http://localhost:3001/read-value', { argument: 'total', rowNumberID: rowNumberID });
      const { cellValue } = response.data;
      setCellValue(cellValue);
    } catch (error) {
      console.error('Error getting cell value:', error);
    }
  }, [rowNumberID]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);



  





  /*
  const [showPricingTable, setShowPricingTable] = useState(false);

  useEffect(() => {
    if (showPricingTable) {
      const script = document.createElement("script");
      script.src = "https://js.stripe.com/v3/pricing-table.js";
      script.async = true;
      document.body.appendChild(script);
      
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [showPricingTable]);

  const handleIncreaseLimit = () => {
    setShowPricingTable(true);
  };
  */

  const popup = document.getElementById("popup");
  const popupOverlay = document.getElementById("popup-overlay");

  const openPopup = () => {
    popup.classList.add("open-popup")
    popupOverlay.style.display = "block";
    popupOverlay.style.opacity = 1;
  };
  const closePopup = () => {
    popup.classList.remove("open-popup")
    popupOverlay.style.opacity = 0; // Set opacity to 0 when hiding the overlay
    setTimeout(() => {
        popupOverlay.style.display = "none";
    }, 400); // Adjust the timeout to match the transition duration
  };




  const handleLogout = () => {
    // Call the onLogout function passed from the parent component
    onLogout();
  };



  return (
    <div className="Home">

      <div id="popup-overlay"></div>
      <div className="popup unselectable" id="popup">
        <h1>Not Enough Tokens</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tristique accumsan nunc id dignissim. Maecenas ut aliquam nisl. Curabitur fringilla justo at semper blandit.</p>
        <button className="home-button" onClick={closePopup}>Close Popup // Open link to buy more tokens</button>
      </div>

        <div className="home-mode-toggle" onClick={handleToggleMode}>🌞</div>

        <div className="home-token-container">
          <span className="home-display-token">{token}</span> {/* Display the token */}
        </div>


        <div className="home-main-container">

          <div className="home-container">
            <h2 className="home-h2">Input:</h2>
        
            <form onSubmit={handleSubmit}>
              <textarea id="userInput" placeholder="Paste reviews here" value={message} onChange={handleChange}></textarea>


              <div className="home-container-content">
                <div className="home-info-text-box-left">
                <span className="home-info-text-box-value">{tokenCount}</span>
                </div>

                <div className="home-buttons-container">
                    <button className="home-button" onClick={handleClearInput}>Clear</button>
                    <button className="home-button" onClick={handleSubmit}>Submit</button>
                </div>
              </div>
            </form>
          </div>

          <div className="home-container">
            <h2 className="home-h2">Output:</h2>

            <textarea id="serverOutput" ref={serverOutputRef} value={response} onChange={(e) => setMessage(e.target.value)} readOnly></textarea>
            
            <div className="home-container-content">

              <div className="home-info-text-box-right">
                <span className="home-info-text-box-value">{readCellValue}</span>
              </div>
              
              <div className="home-buttons-container">
                <button className="home-button" onClick={handleCopyToClipboard}>Copy Output</button>
                <button className="home-button" onClick={handleIncreaseLimitRedirect}>Increase Limit</button>
              </div>
            </div>
          </div> 
        </div>

        <div className="home-logout-button-container">
          <button className="home-button" onClick={handleLogout}>Logout</button>
        </div>

    </div>
  );
}

export default Home;